import React, { useState } from "react";
import { MailIcon } from "@heroicons/react/outline";
import Modal from "./Modal";
import {
	SENDINBLUE_API_KEY,
	mail_to,
	mail_to_name,
	mail_subject,
	mail_from,
	mail_from_name,
	sendinblue_url,
} from "../constants";

const ContactForm = ({ showPrivacyNotice, setShowPrivacyNotice }) => {
	const [openModal, setOpenModal] = useState(false);
	const [successEmail, setSuccessEmail] = useState();
	const [failureModalBody, setFailureModalBody] = useState("");

	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const successModalTitle = "¡Gracias!";
	const successModalBody = "Tu mensaje ha sido enviado correctamente.";
	const failureModalTitle = "¡Lo sentimos!";

	const sendEmail = () => {
		const valid = ValidateEmail(email) && ValidatePhone(phone);

		if (valid) {
			const bodyData = `<h3>${mail_subject}</h3>
					<p>Nombre: ${fullName}</p>
					<p>Email: ${email}</p>
					<p>Teléfono: ${phone}</p>
					<p>Mensaje: ${message}</p>`;
			const options = {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"api-key": SENDINBLUE_API_KEY,
				},
				body: JSON.stringify({
					to: [
						{
							email: mail_to,
							name: mail_to_name,
						},
						{
							email: mail_from,
							name: mail_from_name,
						},
					],
					sender: {
						email: mail_from,
						name: mail_from_name,
					},
					subject: mail_subject,
					htmlContent: bodyData,
				}),
			};

			fetch(sendinblue_url, options)
				.then((response) => response.json())
				.then((response) => {
					console.log(response);
					setSuccessEmail(true);
					setOpenModal(true);
				})
				.catch((err) => {
					console.error(err);
					setFailureModalBody("Ha ocurrido un error al enviar tu mensaje.");
					setSuccessEmail(false);
					setOpenModal(true);
				});
		}
	};

	const ValidateEmail = (email) => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return true;
		}
		setFailureModalBody("El email ingresado no es válido.");
		setSuccessEmail(false);
		setOpenModal(true);
		return false;
	};

	const ValidatePhone = (phone) => {
		if (/^\d{10}$/.test(phone)) {
			return true;
		}
		setFailureModalBody(
			"El teléfono ingresado no es válido. Deben ser 10 dígitos."
		);
		setSuccessEmail(false);
		setOpenModal(true);
		return false;
	};

	return (
		<>
			<div className="relative bg-indigo-100">
				<div className="absolute inset-0">
					<div className="absolute inset-y-0 left-0 w-1/2 bg-indigo-100" />
				</div>
				<div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
					<div className="px-4 py-16 bg-indigo-100 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
						<div className="max-w-lg mx-auto">
							<h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
								Contáctanos
							</h2>
							<p className="mt-3 text-lg leading-6 text-gray-500">
								¿Necesitas una cotización?
								<br />
								¿Tienes dudas acerca de algún servicio?
								<br />
								Por favor, proporciónanos tus datos para poder comunicarnos
								contigo.
							</p>
							<dl className="mt-8 text-base text-gray-500">
								<div className="mt-3">
									<dt className="sr-only">Correo electrónico</dt>
									<dd className="flex">
										<MailIcon
											className="flex-shrink-0 w-6 h-6 text-gray-400"
											aria-hidden="true"
										/>
										<span className="ml-3">contacto@peacsa.com</span>
									</dd>
								</div>
							</dl>
							<br />
						</div>
					</div>
					<Modal
						successEmail={successEmail}
						title={successEmail ? successModalTitle : failureModalTitle}
						body={successEmail ? successModalBody : failureModalBody}
						openModal={openModal}
						setOpenModal={setOpenModal}
					/>
					<div className="px-4 py-8 bg-indigo-100 sm:px-6 lg:col-span-3 lg:pt-24 lg:pb-8 lg:px-8 xl:pl-12">
						<div className="max-w-lg mx-auto lg:max-w-none">
							<form
								action="#"
								method="POST"
								className="grid grid-cols-1 gap-y-6"
							>
								<div>
									<label htmlFor="fullName" className="sr-only">
										Tu nombre
									</label>
									<input
										type="text"
										name="fullName"
										id="fullName"
										autoComplete="name"
										className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
										placeholder="Tu nombre"
										onChange={(e) => setFullName(e.target.value)}
									/>
								</div>
								<div>
									<label htmlFor="email" className="sr-only">
										Correo electrónico
									</label>
									<input
										id="email"
										name="email"
										type="email"
										autoComplete="email"
										className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
										placeholder="Correo electrónico"
										onChange={(e) => setEmail(e.target.value)}
									/>
								</div>
								<div>
									<label htmlFor="phone" className="sr-only">
										Teléfono
									</label>
									<input
										type="text"
										name="phone"
										id="phone"
										autoComplete="tel"
										className="block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
										placeholder="Teléfono"
										onChange={(e) => setPhone(e.target.value)}
									/>
								</div>
								<div>
									<label htmlFor="message" className="sr-only">
										Mensaje
									</label>
									<textarea
										id="message"
										name="message"
										rows={4}
										className="block w-full px-4 py-3 placeholder-gray-500 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
										placeholder="Mensaje"
										defaultValue={""}
										onChange={(e) => setMessage(e.target.value)}
									/>
								</div>
								<div className="inline-flex justify-center">
									<button
										type="button"
										className="px-6 py-3 text-base font-medium text-white transition duration-500 ease-in-out bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800"
										onClick={sendEmail}
									>
										¡Enviar ahora!
									</button>
								</div>
							</form>
							<br />
							<p className="mt-6 text-base text-gray-500">
								* Al mandarnos un mensaje, nos autorizas a comunicarnos contigo,
								de acuerdo a nuestras políticas de privacidad.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="py-2 pb-2 bg-indigo-100 ">
				<div className="flex flex-row justify-around w-full h-full mb-12 ">
					<button
						type="button"
						className="px-6 py-3 text-base font-medium text-white transition duration-500 ease-in-out bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800"
						onClick={() => setShowPrivacyNotice(!showPrivacyNotice)}
					>
						{showPrivacyNotice ? "Ocultar" : "Mostrar"} Aviso de Privacidad
					</button>
				</div>
			</div>
		</>
	);
};

export default ContactForm;
