export const SENDINBLUE_API_KEY =
	"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";

export const mail_to = "gps1mx@gmail.com";

export const mail_to_name = "GPS1MX";

export const mail_subject = "Contacto desde página Peac Software";

export const mail_from = "contacto@peacsa.com";

export const mail_from_name = "Peac Software";

export const sendinblue_url = "https://api.sendinblue.com/v3/smtp/email";

export const company_name = "Peac Software SA de CV";

export const short_company_name = "Peac Software";

export const contact_email = "contacto@peacsa.com";

export const webpage = "https://peacsa.com/";

export const company_address =
	"Av Sierra de Zimapán 4 Interior 9, Villas del Sol, Querétaro, Querétaro, CP 76046";

export const company_city = "Querétaro, México";

export const year = new Date().getFullYear();
