import { Link } from "react-scroll";

const CTA = () => {
	return (
		<div className="bg-white">
			<div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
				<h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
					<span className="block">¿Listo para empezar?</span>
					<span className="block pb-1 -mb-1 text-transparent bg-gradient-to-r from-purple-600 to-indigo-700 bg-clip-text">
						Permítenos comunicarnos contigo
					</span>
					<span className="block pb-1 -mb-1 text-transparent bg-gradient-to-r from-purple-600 to-indigo-700 bg-clip-text">
						¡Hagamos equipo!
					</span>
				</h2>
				<div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
					<Link
						spy={true}
						smooth={true}
						duration={1500}
						to="contact_form"
						className="flex items-center justify-center px-4 py-3 text-base font-medium text-indigo-800 transition duration-500 ease-in-out bg-indigo-100 border border-transparent rounded-md shadow-sm hover:bg-indigo-500 hover:text-white"
					>
						Empezar
					</Link>
				</div>
			</div>
		</div>
	);
};

export default CTA;
