import { Link } from "react-scroll";

import { InboxIcon, SparklesIcon } from "@heroicons/react/outline";

const Feature1 = () => {
	return (
		<div className="relative pt-16 pb-32 overflow-hidden">
			<div
				aria-hidden="true"
				className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
			/>
			<div className="relative">
				<div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
					<div className="max-w-xl px-4 mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
						<div>
							<div>
								<span className="flex items-center justify-center w-12 h-12 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600">
									<InboxIcon
										className="w-6 h-6 text-white"
										aria-hidden="true"
									/>
								</span>
							</div>
							<div className="mt-6">
								<h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
									Quienes somos
								</h2>

								<p className="mt-4 text-lg text-gray-500">
									Somos un equipo de profesionales en desarrollo de software de
									gran calidad, con más de 15 años ayudando a nuestros clientes
									a lograr sus metas.
								</p>
								<p className="mt-4 text-lg text-gray-500">
									Amamos diseñar interfaces claras y fáciles de usar, para
									generar una gran experiencia al usar nuestro software.
								</p>
								<p className="mt-4 text-lg text-gray-500">
									Nos ayudan expertos en áreas tan diversas como contabilidad y
									finanzas, la gestión empresarial, educación, aeronáutica,
									seguridad en sistemas, y diversas áreas de ingeniería.
								</p>
								<p className="mt-4 text-lg text-gray-500">
									Estamos comprometidos en encontrar la mejor solución a las
									necesidades de nuestros clientes, aportando lo mejor de
									nosotros en el diseño y creación de software de alta calidad.
								</p>
								<p className="mt-4 text-lg italic font-semibold text-gray-500">
									Nos encanta superar las expectativas de nuestros clientes.
								</p>
								<div className="mt-6">
									<Link
										spy={true}
										smooth={true}
										duration={1500}
										to="contact_form"
										className="inline-flex px-4 py-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border hover:from-purple-700 hover:to-indigo-700"
									>
										Empieza ya!
									</Link>
								</div>
							</div>
						</div>
						<div className="pt-6 mt-8 border-t border-gray-200">
							<blockquote>
								<div>
									<p className="text-base text-gray-500">
										&ldquo;Como manager del Salón Corona, una de mis principales
										responsabilidades es ofrecerle al cliente la mejor
										experiencia, para lo que hay que darle un servicio rápido y
										correcto. Después de probar con muchas otras empresas, Peac
										fue la única empresa que nos pudo hacer la solución que
										necesitábamos, integrando todas nuestras peticiones
										perfectamente.&rdquo;
									</p>
									<br />
									<p className="text-base text-gray-500">
										&ldquo;Ahora, en el Restaurante El 4:20, Peac fue la
										elección obvia para que hiciera nuestros sistemas. &rdquo;
									</p>
								</div>
								<footer className="mt-3">
									<div className="flex items-center space-x-3">
										<div className="text-base font-medium text-gray-700">
											Manuel Penela, Ex-Manager del Salón Corona y del
											Restaurante el 4:20
										</div>
									</div>
								</footer>
							</blockquote>
						</div>
					</div>
					<div className="mt-12 sm:mt-16 lg:mt-0">
						<div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
							<img
								className="w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
								src="https://tailwindui.com/img/component-images/inbox-app-screenshot-1.jpg"
								alt="Inbox user interface"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="mt-24">
				<div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
					<div className="max-w-xl px-4 mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
						<div>
							<div>
								<span className="flex items-center justify-center w-12 h-12 rounded-md bg-gradient-to-r from-purple-600 to-indigo-600">
									<SparklesIcon
										className="w-6 h-6 text-white"
										aria-hidden="true"
									/>
								</span>
							</div>
							<div className="mt-6">
								<h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
									Porqué hacemos lo que hacemos
								</h2>
								<p className="mt-4 text-lg text-gray-500">
									Porque estamos comprometidos con el crecimiento de las
									pequeñas y medianas empresas.
								</p>
								<p className="mt-4 text-lg text-gray-500">
									Por qué estamos convencidos que todo mundo debería estar
									haciendo lo que ama, en lugar de gastar su tiempo con tareas
									que puede hacer un sistema.
								</p>
								<p className="mt-4 text-lg text-gray-500">
									Porque somos unos apasionados por crear{" "}
									<span className="font-semibold">Software Excepcional</span>,
									seguro, eficiente, fácil de usar, y siempre disponible.
								</p>
								<p className="mt-4 text-lg text-gray-500"></p>
								<p className="mt-4 text-lg text-gray-500"></p>
								<p className="mt-4 text-lg text-gray-500"></p>
								<div className="mt-6">
									<Link
										spy={true}
										smooth={true}
										duration={1500}
										to="contact_form"
										className="inline-flex px-4 py-2 text-base font-medium text-white transition duration-500 ease-in-out border border-transparent rounded-md shadow-sm bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border hover:from-purple-700 hover:to-indigo-700"
									>
										Contáctanos
									</Link>
								</div>
							</div>
						</div>

						<div className="pt-6 mt-8 border-t border-gray-200">
							<blockquote>
								<div>
									<p className="text-base text-gray-500">
										&ldquo;Peac es la empresa que ha logrado superar los retos
										tecnológicos para mantenernos en la cima de nuestro negocio.
										Definitivamente son el equipo al que voy a incluir en todos
										mis proyectos.&rdquo;
									</p>
								</div>
								<footer className="mt-3">
									<div className="flex items-center space-x-3">
										<div className="text-base font-medium text-gray-700">
											Edgard Abarca, Director General AdDSA
										</div>
									</div>
								</footer>
							</blockquote>
						</div>
					</div>

					<div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
						<div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
							<img
								className="w-full shadow-xl rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
								src="https://tailwindui.com/img/component-images/inbox-app-screenshot-2.jpg"
								alt="Customer profile user interface"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feature1;
