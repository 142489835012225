import { Link } from "react-scroll";
import main_foto from "../assets/main_photo.jpg";

const Hero = () => {
	return (
		<div className="relative">
			<div className="absolute inset-x-0 bottom-0 bg-gray-100 h-1/2" />
			<div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
					<div className="absolute inset-0">
						<img
							className="object-cover w-full h-full"
							src={main_foto}
							alt="Laptops"
						/>
						<div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
					</div>
					<div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
						<h1 className="text-4xl font-extrabold tracking-tight text-center sm:text-5xl lg:text-6xl">
							<span className="block text-white">PEAC</span>
							<span className="block text-indigo-200">Software</span>
						</h1>
						<p className="max-w-lg mx-auto mt-6 text-xl text-center text-indigo-200 sm:max-w-3xl">
							Profesionales en el desarrollo de software a la medida
						</p>
						<div className="max-w-sm mx-auto mt-10 sm:max-w-none sm:flex sm:justify-center">
							<div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid ">
								<Link
									spy={true}
									smooth={true}
									duration={1500}
									offset={20}
									to="contact_form"
									className="flex items-center justify-center px-4 py-3 text-base font-medium text-indigo-700 transition duration-500 ease-in-out bg-white border border-transparent rounded-md shadow-sm hover:bg-indigo-400 hover:text-white sm:px-8"
								>
									Contáctanos
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Hero;
