import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { useState } from "react";

import ContactForm from "./Components/ContactForm";
import CTA from "./Components/CTA";
import Feature1 from "./Components/Feature1";
import Feature2 from "./Components/Feature2";
import Footer from "./Components/Footer";

import Header from "./Components/Header";
import Hero from "./Components/Hero";
import PrivacyNotice from "./Components/PrivacyNotice";
import Stats from "./Components/Stats";

export default function App() {
	const [showPrivacyNotice, setShowPrivacyNotice] = useState(false);

	console.log("showPrivacyNotice", showPrivacyNotice);
	return (
		<div className="bg-white">
			<Header
				showPrivacyNotice={showPrivacyNotice}
				setShowPrivacyNotice={setShowPrivacyNotice}
			/>
			<main>
				<Hero />

				<div id="feature1">
					<Feature1 />
				</div>

				<div id="feature2">
					<Feature2 />
				</div>

				<div id="stats">
					<Stats />
				</div>

				<CTA />

				<div id="contact_form">
					<ContactForm
						showPrivacyNotice={showPrivacyNotice}
						setShowPrivacyNotice={setShowPrivacyNotice}
					/>
				</div>

				<div id="privacy">
					{showPrivacyNotice && (
						<PrivacyNotice
							showPrivacyNotice={showPrivacyNotice}
							setShowPrivacyNotice={setShowPrivacyNotice}
						/>
					)}
				</div>
			</main>
			<Footer />
		</div>
	);
}
