import React from "react";

const metrics = [
	{
		id: 1,
		stat: "Millones",
		emphasis: "De registros",
		rest: "en bases de datos, para obtener la información que necesitas",
	},
	{
		id: 2,
		stat: "100+",
		emphasis: "Proyectos",
		rest: "exitosos",
	},
	{
		id: 3,
		stat: "98%",
		emphasis: "Satisfacción",
		rest: "de nuestros clientes y usuarios",
	},
	{
		id: 4,
		stat: "99.5%+",
		emphasis: "Disponibilidad",
		rest: "para que tengas acceso a tu información siempre",
	},
];

const Stats = () => {
	return (
		<div className="relative bg-gray-900">
			<div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
				<div className="w-full h-full xl:grid xl:grid-cols-2">
					<div className="h-full xl:relative xl:col-start-2">
						<img
							className="object-cover w-full h-full opacity-25 xl:absolute xl:inset-0"
							src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
							alt="People working on laptops"
						/>
						<div
							aria-hidden="true"
							className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
						/>
					</div>
				</div>
			</div>
			<div className="max-w-4xl px-4 mx-auto sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
				<div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
					<h2 className="text-sm font-semibold tracking-wide uppercase">
						<span className="text-transparent bg-gradient-to-r from-purple-300 to-indigo-300 bg-clip-text">
							Métricas valiosas
						</span>
					</h2>
					<p className="mt-3 text-3xl font-extrabold text-white">
						Toma decisiones con datos
					</p>
					<p className="mt-5 text-lg text-gray-300">
						Lleva a tu empresa al siguiente nivel, con información en tiempo
						real. Toma las mejores decisiones apoyado por datos al día, en tus
						operaciones. Ten siempre a la mano todos los datos que necesitas
						para tomar las mejores decisiones.
					</p>
					<div className="grid grid-cols-1 mt-12 gap-y-12 gap-x-6 sm:grid-cols-2">
						{metrics.map((item) => (
							<p key={item.id}>
								<span className="block text-2xl font-bold text-white">
									{item.stat}
								</span>
								<span className="block mt-1 text-base text-gray-300">
									<span className="font-medium text-white">
										{item.emphasis}
									</span>{" "}
									{item.rest}
								</span>
							</p>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Stats;
