import {
	// AnnotationIcon,
	// ChatAlt2Icon,
	// ChatAltIcon,
	DocumentReportIcon,
	HeartIcon,
	InboxIcon,
	// PencilAltIcon,
	// QuestionMarkCircleIcon,
	ReplyIcon,
	// TrashIcon,
	UsersIcon,
	AcademicCapIcon,
	BadgeCheckIcon,
	DatabaseIcon,
} from "@heroicons/react/outline";

const features = [
	{
		name: "Atención continua",
		description:
			"Con un punto de contacto, siempre sabes con quien debes hablar para lograr lo que deseas.",
		icon: InboxIcon,
	},
	{
		name: "Trabajo en equipo",
		description:
			"Con un equipo seleccionado para generar la mejor solución a tus necesidades.",
		icon: UsersIcon,
	},
	{
		name: "Siempre disponible",
		description:
			"¿Tienes internet? Entonces, tienes sistema. Nuestro modelo garantiza una disponibilidad superior al 99.5%.",
		icon: AcademicCapIcon,
	},
	{
		name: "Mejora continua",
		description:
			"Nuestro equipo de desarrollo está siempre en constante mejora para ofrecerte la mejor solución.",
		icon: DocumentReportIcon,
		// icon: PencilAltIcon,
	},
	{
		name: "Pensado para la seguridad",
		description:
			"Nuestros sistemas están pensados para proteger la información de nuestros clientes.",
		icon: BadgeCheckIcon,
		// icon: DocumentReportIcon,
	},
	{
		name: "Respuesta inmediata",
		description:
			"Nuestros sistemas están diseñados para responder a las necesidades de nuestros clientes inmediatamente.",
		icon: ReplyIcon,
	},
	{
		name: "Copias de seguridad",
		description:
			"Con copias de seguridad incluidas, sin costo adicional para ti.",
		icon: DatabaseIcon,
	},
	{
		name: "Haz felices a tus clientes y usuarios",
		description:
			"Con las mejores herramientas para que tus clientes y usuarios se sientan seguros.",
		icon: HeartIcon,
	},
];
const Feature2 = () => {
	return (
		<div className="bg-gradient-to-r from-purple-800 to-indigo-700">
			<div className="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
				<h2 className="text-3xl font-extrabold tracking-tight text-white">
					Con un equipo enfocado en la eficiencia
				</h2>
				<p className="max-w-3xl mt-4 text-lg text-purple-200">
					Cada una de nuestras actividades está enfocada en proveer la solución
					correcta, en el momento adecuado.
				</p>
				<div className="grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
					{features.map((feature) => (
						<div key={feature.name}>
							<div>
								<span className="flex items-center justify-center w-12 h-12 bg-white rounded-md bg-opacity-10">
									<feature.icon
										className="w-6 h-6 text-white"
										aria-hidden="true"
									/>
								</span>
							</div>
							<div className="mt-6">
								<h3 className="text-lg font-medium text-white">
									{feature.name}
								</h3>
								<p className="mt-2 text-base text-purple-200">
									{feature.description}
								</p>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Feature2;
