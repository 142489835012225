import { Link } from "react-scroll";
import {
	company_address,
	company_city,
	company_name,
	contact_email,
	webpage,
} from "../constants";

const PrivacyNotice = ({ showPrivacyNotice, setShowPrivacyNotice }) => {
	return (
		<div className="mx-8 my-6">
			<h2 className="text-3xl font-extrabold tracking-tight text-center text-gray-900">
				Aviso de Privacidad
			</h2>

			<p className="mt-4 text-lg text-justify text-gray-500">
				El presente Aviso de Privacidad aplicará para todos los productos,
				servicios, programas, y/o sitios web, etc. que tenga{" "}
				<span className="text-gray-800">{company_name}</span>, (la cual, en
				adelante, será llamada "
				<span className="text-gray-800">La Compañía</span>").
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				De acuerdo a la naturaleza de los Datos Personales recabados y conforme
				a la legislación vigente en materia de privacidad y protección de Datos
				Personales, en especial la Ley Federal de Protección de Datos Personales
				en Posesión de Particulares, La Compañía podrá publicar nuevos Avisos de
				Privacidad específicos o actualizaciones y para los cuales se podrá
				requerir o no, el consentimiento expreso del titular de los Datos
				Personales, sin embargo a través de esta página de internet {webpage} se
				hacen del conocimiento público y de nuestros clientes las políticas de
				privacidad integral aplicables a los Datos Personales que nos hayan sido
				otorgados.{" "}
			</p>

			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				Nombre y domicilio del responsable de los datos personales
			</h2>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Para efectos de la divulgación y tratamiento de los Datos Personales que
				Usted haya divulgado o pudiera llegar a divulgar, a través de diversos
				medios y formas incluyendo nuestros sitios de Internet, herramientas
				tecnológicas, o directamente a nuestros representantes, con motivo de la
				relación o posible relación por el uso de uno de nuestros productos, así
				como para cualquier otro servicio o actividad relacionada, se
				considerará que el responsable es La Compañía, quien es una sociedad
				constituida de conformidad con las leyes de la República Mexicana, con
				domicilio en {company_address}.
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Para cualquier información sobre este Aviso de Privacidad, o para el
				ejercicio de cualquiera de sus derechos derivados de sus Datos
				Personales, el presente Aviso de Privacidad o de la ley aplicable,
				incluyendo sin limitación sus derechos de acceso, rectificación,
				cancelación y oposición y revocación del consentimiento, por favor
				contactar a nuestro Departamento de Privacidad, mediante el correo
				electrónico {contact_email}, o a través de nuestro formulario de
				contacto, dando{" "}
				<Link
					spy={true}
					smooth={true}
					duration={1500}
					offset={20}
					to="contact_form"
					className="text-base font-medium text-indigo-500 hover:text-gray-900 hover:cursor-pointer"
				>
					CLICK AQUÍ
				</Link>
				.
			</p>

			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				Finalidades y uso de sus datos personales.
			</h2>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Los Datos Personales que recabamos, incluyendo los datos personales
				sensibles, incluyen nombres, domicilios, teléfonos, claves de registro
				federal de contribuyentes, estado civil, nacionalidad, edad, género,
				información laboral, información financiera, datos de terceras personas
				para efectos de referencias.
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Nosotros utilizaremos sus Datos Personales para:
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(i) Atender su solicitud de información, de productos o solicitud de uso
				de los mismos, incluyendo única y expresamente los servicios que
				brindamos;
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(ii) Atender cualquier queja, pregunta o comentario;
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(iii) Enviarles notificaciones, avisos, propaganda o publicidad sobre
				nuestros productos o servicios en la medida que así lo permita la
				legislación aplicable;
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(iv) Envío de información y noticias de nuestros servicios, así como
				noticias, comunicaciones o publicidad de La Compañía o sus empresas
				relacionadas;
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(v) Crear bases de datos (incluyendo bases de datos respecto a datos
				sensibles) para fines estadísticos, para cualquiera de los fines
				mencionados en el inciso (i) anterior o para llevar a cabo cotizaciones;
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(vi) Crear bases de datos para fines de investigación y desarrollo de
				nuevos productos o servicios;
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(vii) Enviarle notificaciones de cambios a este aviso de privacidad; o
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				(viii) Cumplir con leyes o normatividad aplicable al negocio de La
				Compañía
			</p>

			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				Derechos de Acceso, Rectificación, Oposición y Revocación del
				Consentimiento (Derechos Arco)
			</h2>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Para cualquier duda o comentario en relación con sus Datos Personales en
				posesión de La Compañía, usted podrá contactar al Departamento de
				Privacidad de La Compañía, al correo electrónico y/o teléfono que
				aparecen anteriormente en nuestros datos de contacto, o a través de
				nuestro formulario de contacto, dando{" "}
				<Link
					spy={true}
					smooth={true}
					duration={1500}
					offset={20}
					to="contact_form"
					className="text-base font-medium text-indigo-500 hover:text-gray-900 hover:cursor-pointer"
				>
					CLICK AQUÍ
				</Link>
				.
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				De igual forma, Usted podrá solicitar acceder a sus datos,
				rectificarlos, cancelarlos, oponerse, limitar su uso o divulgación o
				revocar su consentimiento, en términos de la Ley Federal de Protección
				de Datos Personales en Posesión de los Particulares y demás
				disposiciones aplicables. Para ejercer estos derechos, Usted deberá
				contactar al Departamento de Privacidad, al correo electrónico y/o
				teléfono que aparecen anteriormente en nuestros datos de contacto, o a
				través de nuestro formulario de contacto, dando{" "}
				<Link
					spy={true}
					smooth={true}
					duration={1500}
					offset={20}
					to="contact_form"
					className="text-base font-medium text-indigo-500 hover:text-gray-900 hover:cursor-pointer"
				>
					CLICK AQUÍ
				</Link>
				.
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				En términos de la ley aplicable, cualquier solicitud de ejercicio de los
				derechos mencionados deberé indicar:{" "}
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				i) Su nombre y domicilio, requisito indispensable para darle respuesta,
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				ii) Una copia de su identificación oficial (pasaporte, credencial de
				elector o cédula profesional con fotografía)
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				iii) La descripción clara y precisa de los Datos Personales a los que
				desea acceder o que desea rectificar, cancelar, oponerse o revocar y
				cualquier otro elemento que facilite la localización de sus datos, así
				como,
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				iv) Cualquier otro requisito establecido por la Ley Federal de
				Protección de Datos Personales en Posesión de los Particulares y/o demás
				disposiciones aplicables.
			</p>

			<p className="mt-4 text-lg italic text-justify text-gray-800">
				En cualquier momento usted podrá solicitar que se suspenda o cancele el
				envío de mensajes o avisos comerciales e información de nuevos
				productos, a través de nuestro Departamento de Privacidad, o a través de
				nuestro formulario de contacto, dando{" "}
				<Link
					spy={true}
					smooth={true}
					duration={1500}
					offset={20}
					to="contact_form"
					className="text-base font-medium text-indigo-500 hover:text-gray-900 hover:cursor-pointer"
				>
					CLICK AQUÍ
				</Link>
			</p>

			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				Transferencia de sus datos personales
			</h2>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Nosotros podremos compartir todos o parte de sus Datos Personales con
				cualquiera de las empresas relacionadas con La Compañía; así como con
				entidades autorizadas de acuerdo a la Legislación Mexicana para la
				supervisión de la realización de nuestras actividades y operaciones de
				nuestros productos, quienes podrán o no tratar sus Datos Personales por
				cuenta de La Compañía conforme a las Finalidades y Usos previstos en el
				presente aviso de privacidad. Asimismo, La Compañía se reserva el
				derecho de compartir sus Datos Personales con autoridades
				administrativas, judiciales o gubernamentales de cualquier tipo, de
				conformidad con lo requerido por las Leyes Mexicanas
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Finalmente, La Compañía podrá transferir sus Datos Personales a asesores
				y prestadores de servicio de cobranza, cualquier adquirente de la
				empresa, de los activos o alguna división o negocio de La Compañía, así
				como a cualquier subsidiaria. Salvo esos casos, La Compañía no
				compartirá o transferirá sus Datos Personales a terceros salvo en los
				casos previstos en la Ley Federal de Protección de Datos Personales en
				Posesión de los Particulares o cualquier otra legislación o reglamento
				aplicable.
			</p>

			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				Almacenamiento de sus datos personales
			</h2>

			<p className="mt-4 text-lg text-justify text-gray-500">
				La Compañía podrá conservar sus Datos Personales en bases de datos
				ubicadas en los Estados Unidos Mexicanos o en el extranjero sin
				limitación alguna, en el entendido de que se han implementado políticas
				y estándares comerciales de tecnología y seguridad para proteger la
				información que nos haya sido proporcionada.
			</p>

			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				Modificaciones al Aviso de Privacidad, Legislación y Jurisdicción
			</h2>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Nosotros nos reservamos el derecho a modificar los términos y
				condiciones de este Aviso de Privacidad, en cuyo caso la modificación se
				notificará a través del medio de comunicación que La Compañía considere
				más adecuados para tal efecto, pudiendo ser:
			</p>

			<p className="mt-4 text-lg text-gray-500">1. Correo electrónico,</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				2. Avisos en medios de comunicación,{" "}
			</p>

			<p className="mt-4 text-lg text-gray-500">3. Comunicación directa </p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				4. Un anuncio en nuestra página de Internet{" "}
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				5. Un anuncio en nuestras sucursales.
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				En cualquier caso, Usted tendrá el derecho a cancelar y/o rectificar sus
				Datos Personales así como a limitar su uso y divulgación en caso de
				cambios en los términos de este aviso de privacidad.
			</p>

			<p className="mt-4 text-lg text-justify text-gray-500">
				Este Aviso de Privacidad, el tratamiento de sus Datos Personales y/o
				todos los documentos relacionados se rigen por la Ley Federal de
				Protección de Datos Personales en Posesión de los Particulares y las
				demás leyes y reglamentos de los Estados Unidos Mexicanos. La aceptación
				de este Aviso de Privacidad o la simple continuación en la solicitud de
				servicios una vez publicado y puesto a disposición el Aviso de
				Privacidad implica una aceptación expresa de los términos del mismo y su
				sometimiento expreso a los tribunales de la Ciudad de {company_city},
				para cualquier controversia o reclamación derivada de este Aviso de
				Privacidad.
			</p>

			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				Quejas por tratamiento indebido de los Datos Personales.
			</h2>
			<h2 className="mt-6 text-xl font-extrabold tracking-tight text-center text-gray-900">
				IFAI
			</h2>

			<p className="mt-4 text-lg text-center text-gray-500">
				Insurgentes Sur No. 3211 Col. Insurgentes Cuicuilco, Delegación
				Coyoacán,
			</p>
			<p className="mt-4 text-lg text-center text-gray-500">
				C.P. 04530 Tel. 01800 8354 321 atencion@ifai.org.mx www.ifai.org.mx{" "}
			</p>

			<div className="flex flex-row justify-around w-full h-full my-8 ">
				<button
					type="button"
					className="px-6 py-3 text-base font-medium text-white transition duration-500 ease-in-out bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-800"
					onClick={() => setShowPrivacyNotice(!showPrivacyNotice)}
				>
					{showPrivacyNotice ? "Ocultar" : "Mostrar"} Aviso de Privacidad
				</button>
			</div>
		</div>
	);
};

export default PrivacyNotice;
